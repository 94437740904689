export default [{
  name: 'ID товара',
  sortable: 'true',
  key: 'id',
  type: 'id',
  styles: { minWidth: '190px' }
}, {
  name: 'Наименование',
  sortable: 'true',
  key: 'name',
  type: 'name'
}, {
  name: 'Цена',
  sortable: 'true',
  key: 'product_unit_price_amount',
  type: 'price',
  styleType: 'numbers',
  styles: { minWidth: '100px' }
}, {
  name: 'Кол-во',
  sortable: 'true',
  key: 'product_count',
  type: 'product_count',
  styleType: 'numbers',
  styles: { minWidth: '150px' }
}, {
  name: 'Сумма',
  sortable: 'true',
  key: 'total_product_amount',
  type: 'price',
  styleType: 'numbers',
  styles: { minWidth: '150px' }
}, {
  name: 'Скидка',
  sortable: 'true',
  key: 'discount_amount_sum',
  styleType: 'numbers',
  type: 'discount_amount_sum',
  styles: { minWidth: '150px' }
}, {
  name: 'Сумма продажи',
  sortable: 'true',
  type: 'price',
  key: 'total_real_amount',
  styleType: 'numbers',
  styles: { minWidth: '150px' }
}, {
  name: 'Стоимость доставки',
  sortable: 'true',
  type: 'price',
  key: 'logistics_amount',
  styleType: 'numbers',
  styles: { minWidth: '150px' }
}]
