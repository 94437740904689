export const statuses = {
  IN_WAREHOUSE: 'IN_WAREHOUSE',
  IN_DELIVERY_WAREHOUSE: 'IN_DELIVERY_WAREHOUSE',
  ON_THE_WAY: 'ON_THE_WAY',
  DELIVERED: 'DELIVERED'
}

export const statusesText = {
  [statuses.IN_WAREHOUSE]: 'На складе',
  [statuses.IN_DELIVERY_WAREHOUSE]: 'На складе курьерской службы',
  [statuses.ON_THE_WAY]: 'В пути к пункту назначения',
  [statuses.DELIVERED]: 'Доставлен'
}

export const payColors = {
  [statuses.IN_WAREHOUSE]: 'light-blue-lighten-5',
  [statuses.IN_DELIVERY_WAREHOUSE]: 'light-blue-lighten-5',
  [statuses.ON_THE_WAY]: 'success-lightest-2',
  [statuses.DELIVERED]: 'success'
}
