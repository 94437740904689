<template>
  <div class="row">
    <property-list
      :item="order"
      :properties="columns.firstColumn"
      class="col col-4"
    />

    <property-list
      :item="order"
      :properties="columns.secondColumn"
      class="col col-4"
    />
  </div>
</template>

<script>
import PropertyList from '@/components/Interface/PropertyList.vue'
import columns from './columns'

export default {
  components: {
    PropertyList
  },
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      columns
    }
  }
}
</script>
