<template>
  <x-form-item
    name="Магазин"
    label="Магазин"
    label-size="s"
  >
    <x-select
      v-bind="$attrs"
      track-by="id"
      label="label"
      :value="shop"
      :options="computedShops"
      :searchable="false"
      :allow-empty="false"
      :loading="isLoading"
      :option-prepend-icon="getShopIcon"
      placeholder="Магазин"
      class="shop-filter"
      @select="option => $emit('change:shop', option)"
      @open="fetchShops"
    />
  </x-form-item>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { defaultFilterOption } from '@/constants/dashboards-filters'

const { mapActions } = createNamespacedHelpers('marketplaces')

const mappedIcons = {
  beru: 'yandex-market',
  goods: 'sber-mega-market',
  ali: 'aliexpress'
}

export default {
  inheritAttrs: false,

  props: {
    shops: {
      type: Array,
      default: () => []
    },
    shop: {
      type: Object,
      default: () => defaultFilterOption
    },
    marketplace: {
      type: Object,
      default: null
    },
    params: {
      type: Object,
      default: undefined
    }
  },

  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    computedShops () {
      let shopsOptions = this.shops.map(m => ({
        id: m.id,
        label: m.shop.name,
        marketplace_code: m.marketplace_code,
        $isDisabled: m.is_paywalled_in_lkp
      }))

      if (this.marketplace && this.marketplace.id !== 'ALL') {
        shopsOptions = shopsOptions.filter(s => s.marketplace_code === this.marketplace.code)
      }

      shopsOptions.unshift(defaultFilterOption)

      return shopsOptions
    }
  },

  beforeDestroy () {
    this.clearShopList()
  },

  methods: {
    ...mapActions(['getShopList', 'clearShopList']),

    getShopIcon (option) {
      if (option.id === 'ALL') return 'bag'
      if (mappedIcons[option.marketplace_code]) {
        return mappedIcons[option.marketplace_code]
      }
      return this.shops.find(s => s.id === option.id)?.marketplace_code
    },

    async fetchShops () {
      if (this.shops.length) return
      try {
        this.isLoading = true

        await this.getShopList(this.params)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
