<template>
  <div class="row">
    <property-list
      :item="order"
      :properties="columns.firstColumn"
      class="col col-4"
    >
      <template #date="{ date }">
        {{ date ? formatDate(date) : '-' }}
      </template>

      <template #track_number="{ track_number }">
        <div class="d-flex align-center">
          <span>{{ track_number }}</span>
          <x-btn
            v-if="track_number"
            v-clipboard:copy="track_number"
            size="xs"
            icon="copy"
            ghost
            @click="$emit('copied')"
          />
          <span v-else>-</span>
        </div>
      </template>

      <template #track_url="{ track_url }">
        <a
          v-if="track_url"
          :href="track_url"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ track_url }}
        </a>
        <template v-else>
          -
        </template>
      </template>
    </property-list>

    <property-list
      :item="order"
      :properties="columns.secondColumn"
      class="col col-4"
    >
      <template #status="{ status }">
        <delivery-status :status="status" />
      </template>

      <template #delivery_price="{ delivery_price }">
        {{ formatPrice(delivery_price) }} руб
      </template>

      <template #warehouse="{ warehouse }">
        <template v-if="warehouse">
          {{ warehouse.name }}
        </template>
      </template>

      <template #date="{ date }">
        {{ date ? formatDate(date) : '-' }}
      </template>

      <template #express="{ express }">
        {{ express ? 'Да' : 'Нет' }}
      </template>
    </property-list>
  </div>
</template>

<script>
import PropertyList from '@/components/Interface/PropertyList.vue'
import DeliveryStatus from '../DeliveryStatus/DeliveryStatus.vue'
import formatPrice from '@/utils/price-formatter'
import formatDate from '@/utils/date-formatter'
import columns from './columns'

export default {
  components: {
    PropertyList,
    DeliveryStatus
  },
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      columns
    }
  },
  methods: {
    formatPrice,
    formatDate
  }
}
</script>
