<template>
  <div>
    <component
      :is="componentName"
      :order="order"
      :marketplace="marketplace"
    />
  </div>
</template>

<script>
import DefaultInfo from './components/DefaultInfo/DefaultInfo.vue'
import OzonInfo from './components/OzonInfo/OzonInfo.vue'
import MARKETPLACES from '@/constants/marketplace-codes'

export default {
  components: {
    DefaultInfo,
    OzonInfo
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    marketplace () {
      return this.order.marketplace.code
    },
    componentName () {
      switch (this.marketplace) {
        case MARKETPLACES.OZON:
          return 'OzonInfo'

        default:
          return 'DefaultInfo'
      }
    }
  }
}
</script>
