export default {
  firstColumn: [{
    name: 'Магазин',
    key: 'shop',
    type: 'shop'
  }, {
    name: 'Дата создания заказа',
    key: 'gmt_create',
    type: 'date'
  }, {
    name: 'Дата выполнения заказа',
    key: 'gmt_trade_end',
    type: 'date'
  }, {
    name: 'Схема работы',
    key: 'service_type',
    type: 'fbo'
  }],
  secondColumn: [{
    name: 'Статус заказа',
    key: 'xway_status',
    type: 'xway_status'
  }, {
    name: 'Статус оплаты',
    key: 'fund_status',
    type: 'fund_status'
  }, {
    name: 'Причина отмены',
    key: 'cancel_reason'
  }, {
    name: 'Время до отправки',
    key: 'shipment_date',
    type: 'shipment_date'
  }]
}
