<template>
  <component
    :is="componentName"
    :order="order"
    :marketplace="marketplace"
    @copied="$emit('copied')"
  />
</template>

<script>
import DefaultDelivery from './components/DefaultDelivery/DefaultDelivery.vue'
import OzonDelivery from './components/OzonDelivery/OzonDelivery.vue'
import MARKETPLACES from '@/constants/marketplace-codes'

export default {
  components: {
    DefaultDelivery,
    OzonDelivery
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    marketplace () {
      return this.order.marketplace.code
    },
    componentName () {
      switch (this.marketplace) {
        case MARKETPLACES.OZON:
          return 'OzonDelivery'

        default:
          return 'DefaultDelivery'
      }
    }
  }
}
</script>
