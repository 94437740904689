<template>
  <x-card
    class="order-products"
    size="xl"
  >
    <div class="headline-5 mb-6">
      Подробности заказа
    </div>
    <grid
      auto
      no-hover-bg
      default-sort-by="id"
      :cells="order.order_items"
      :columns="columns"
    >
      <template #id="{ row }">
        <div class="order-products__meta">
          <x-img
            :src="row.image_url"
            class="mr-2"
          />
          <div>
            <div class="d-flex align-center">
              {{ row.id }}
              <x-link
                v-if="row.link"
                :href="row.link"
                class="order-products__product-link ml-1"
              >
                <x-icon
                  name="link"
                  size="xs"
                />
              </x-link>
            </div>
            <div>
              <div
                v-if="row.sku_code"
                v-clipboard:copy="(row.sku_code).toString()"
                class="caption gray-darker--text d-inline-flex align-center copy-text"
                @click="$emit('copied')"
              >
                <span>Арт. МП: {{ row.sku_code }}</span>
                <x-icon
                  name="copy"
                  size="xxs"
                  class="ml-1"
                />
              </div>
            </div>
            <div>
              <div
                v-if="row.internal_id"
                v-clipboard:copy="(row.internal_id).toString()"
                class="caption gray-darker--text d-inline-flex align-center copy-text"
                @click="$emit('copied')"
              >
                <span>Арт. внут.: {{ row.internal_id }}</span>
                <x-icon
                  name="copy"
                  size="xxs"
                  class="ml-1"
                />
              </div>
            </div>
            <div>
              <div
                v-if="row.barcode"
                v-clipboard:copy="(row.barcode).toString()"
                class="caption gray-darker--text d-inline-flex align-center copy-text"
                @click="$emit('copied')"
              >
                <span>Штрихкод: {{ row.barcode }}</span>
                <x-icon
                  name="copy"
                  size="xxs"
                  class="ml-1"
                />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #name="{ row }">
        <tooltip
          v-if="row.name"
          no-arrow
        >
          <span class="order-products__name">
            {{ row.name }}
          </span>
          <template #popover>
            {{ row.name }}
          </template>
        </tooltip>
      </template>

      <template #discount_amount_sum="{ discount_amount_sum }">
        {{ formatPrice(discount_amount_sum) }} руб.
      </template>

      <template #price="{ price }">
        <template v-if="price">
          {{ formatPrice(price) }} руб.
        </template>
      </template>

      <template #product_count="{ product_count }">
        {{ product_count }} шт
      </template>

      <template #append-item="{ cell }">
        <div
          v-if="cell.memo"
          class="order-memo"
        >
          <Comment>
            <template #title>
              <div class="mb-1 font-weight-semibold">
                Комментарий к заказу
              </div>
            </template>
            <template #text>
              <div>{{ cell.memo }}</div>
            </template>
          </Comment>
        </div>
      </template>

      <template #total-values>
        <tr class="order-products__total font-weight-bold grid__cell grid__cell_viewtype_numbers">
          <td
            class="total-text"
            colspan="3"
          >
            Итого
          </td>
          <td>
            <div class="grid__cell-in">
              <div>{{ totalCount('product_count').split(',')[0] }} шт</div>
            </div>
          </td>
          <td>
            <div class="grid__cell-in">
              <div>{{ totalCount('total_product_amount') }} руб.</div>
            </div>
          </td>
          <td>
            <div class="grid__cell-in">
              <div>{{ totalCount('discount_amount_sum') }} руб.</div>
            </div>
          </td>
          <td>
            <div class="grid__cell-in">
              <div>{{ totalCount('total_real_amount') }} руб.</div>
            </div>
          </td>
          <td>
            <div class="grid__cell-in">
              <div>{{ totalCount('logistics_amount') }} руб.</div>
            </div>
          </td>
        </tr>
      </template>
    </grid>
    <div
      v-if="order.pay_amount"
      class="order-price d-flex align-center headline-6 mt-6"
    >
      Стоимость заказа:
      <div class="order-price__value ml-2 p-2 font-weight-semibold headline-4">
        {{ (+order.rrc_sum).toLocaleString() }} руб.
      </div>
    </div>
  </x-card>
</template>

<script>
import Grid from '@/components/Interface/Grid.vue'
// import ProductMeta from '@/components/Common/ProductMeta.vue'
import Tooltip from '@/components/Common/Tooltip.vue'
import columns from '@/constants/order'
import formatPrice from '@/utils/price-formatter'
import Comment from '@/components/Interface/Comment.vue'

export default {
  components: {
    Grid,
    // ProductMeta,
    Tooltip,
    Comment
  },

  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      columns
    }
  },

  methods: {
    formatPrice,
    totalCount (key) {
      return this.formatPrice(this.order.order_items.reduce((acc, item) => (acc + +(item[key])), 0))
    }
  }
}
</script>

<style lang="stylus" scoped>
  .order-products
    & .grid-container
      overflow-x auto
      padding-bottom 10px

    &__meta
      display flex

    &__product-link
      color: $colors.gray-dark
      &:hover
        color: $colors.primary

    &__total
      height 34px
      border-top 1px solid #d7d6d6
      border-bottom 1px solid #d7d6d6
      background $colors.gray-lightest-2

    &__name
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      -webkit-box-orient: vertical;

  .total-text
    padding 10px

  .order-price
    &__value
      background $colors.success-lightest-2
      border-radius 4px

.order-memo
  padding 5px 0 10px

.copy-text

  cursor pointer
  & span
    white-space nowrap
  & >>> .x-icon
    visibility hidden
  &:hover
    >>> .x-icon
      visibility visible

</style>
