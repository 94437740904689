<template>
  <div class="orders-filters">
    <ul class="orders-filters__list">
      <li class="orders-filters__item">
        <x-form-item
          class="field-composition_content_filter"
          label="Поиск"
          label-size="s"
        >
          <x-input
            v-model="search"
            placeholder="ID заказа"
            prepend-icon="search"
            class="form-field-box_content_filter"
            @input="$emit('search', $event || null)"
          />
        </x-form-item>
      </li>

      <li class="orders-filters__item">
        <shop-filter
          secondary
          border
          :shop="shop"
          :shops="computedShops"
          :marketplace="marketplace"
          @change:shop="$emit('change:shop', $event)"
        />
      </li>

      <li class="orders-filters__item">
        <x-form-item
          label="Статус"
          label-size="s"
        >
          <x-select
            track-by="id"
            label="label"
            placeholder="Все статусы"
            :value="status"
            :options="computedStatuses"
            :limit="1"
            :limit-text="count => `и ещё ${count}`"
            :allow-empty="false"
            @select="$emit('change:order-status', $event)"
          />
        </x-form-item>
      </li>

      <li class="orders-filters__item">
        <div class="d-flex">
          <x-label size="s">
            Самовыкуп
          </x-label>
          <x-icon
            v-hint="{
              placement: 'top',
              content: 'Самовыкуп — это выкуп товара на маркетплейсе для его продвижения в поисковой экосистеме площадки',
              triggers: ['hover'],
              delay: 100
            }"
            name="info"
            size="xs"
            color="gray"
            class="ml-1"
          />
        </div>
        <x-select
          track-by="id"
          label="label"
          :value="selfPurchase"
          :options="SelfPurchaseList"
          :allow-empty="false"
          @select="$emit('change:self-purchase', $event)"
        />
      </li>

      <li class="orders-filters__item orders-filters__item_btn">
        <div>
          <div class="orders-filters__label mb-1" />
          <x-btn-group :group="filtersChanged">
            <x-btn
              outline
              prepend="filter"
              @click="toggleFilters"
            >
              {{ showFilters ? 'Свернуть' : 'Все фильтры' }}
            </x-btn>
            <x-btn
              v-if="filtersChanged"
              square
              icon="x"
              title="Очистить фильтры"
              @click="onReset"
            />
          </x-btn-group>
        </div>
      </li>

      <!-- <li
        v-if="false"
        class="orders-filters__item orders-filters__item_content_checkboxes"
      >
        <checkbox
          v-model="shopDiscount"
          class="orders-filters__checkbox"
          @change="$emit('change:shop-discount', shopDiscount)"
        >
          Скидка магазина
        </checkbox>
        <checkbox
          v-model="marketplaceDiscount"
          class="orders-filters__checkbox"
          @change="$emit('change:marketplace-discount', marketplaceDiscount)"
        >
          Скидка маркетплейса
        </checkbox>
      </li> -->
    </ul>

    <div
      ref="filterList"
      class="orders-filters__collapsed"
      :style="{ height }"
    >
      <ul class="orders-filters__list">
        <li class="orders-filters__item">
          <x-form-item
            label="Дата создания"
            label-size="s"
          >
            <datepicker
              border
              has-reset-button
              :max-date="filtersMaxDate"
              :default-period="currentCreatedPeriod"
              :value="createdPeriod"
              @change="value => $emit('change:created-period', value)"
              @change-default-period="p => currentCreatedPeriod = p"
            />
          </x-form-item>
        </li>

        <li class="orders-filters__item">
          <x-form-item
            label="Дата оплаты"
            label-size="s"
          >
            <datepicker
              border
              has-reset-button
              :max-date="filtersMaxDate"
              :default-period="currentPaymentPeriod"
              :value="paymentPeriod"
              @change="value => $emit('change:payment-period', value)"
              @change-default-period="p => currentPaymentPeriod = p"
            />
          </x-form-item>
        </li>

        <li class="orders-filters__item">
          <x-form-item
            label="Дата отправки"
            label-size="s"
          >
            <datepicker
              border
              has-reset-button
              :max-date="filtersMaxDate"
              :default-period="currentSendPeriod"
              :value="sendPeriod"
              @change="value => $emit('change:send-period', value)"
              @change-default-period="p => currentSendPeriod = p"
            />
          </x-form-item>
        </li>

        <li class="orders-filters__item">
          <x-form-item
            label="Дата спора"
            label-size="s"
          >
            <datepicker
              border
              has-reset-button
              :max-date="filtersMaxDate"
              :default-period="currentDisputePeriod"
              :value="disputePeriod"
              @change="value => $emit('change:dispute-period', value)"
              @change-default-period="p => currentDisputePeriod = p"
            />
          </x-form-item>
        </li>

        <li class="orders-filters__item">
          <div class="d-flex">
            <x-label size="s">
              Схема работы
            </x-label>
            <x-icon
              v-hint="{
                popperClass: 'popper',
                placement: 'top',
                content: textAsHTML,
                triggers: ['hover'],
                delay: 100,
                html: true
              }"
              name="info"
              size="xs"
              color="gray"
              class="ml-1"
            />
          </div>
          <x-select
            track-by="id"
            label="label"
            :value="serviceType"
            :options="serviceTypeList"
            :allow-empty="false"
            @select="$emit('change:service-type', $event)"
          />
        </li>

        <li class="orders-filters__item orders-filters__item_checkbox">
          <div class="orders-filters__label mb-1" />
          <div class="checkbox-block d-flex align-center">
            <x-checkbox
              :model-value="onlyMemo"
              @change="$emit('change:only-memo', $event)"
            >
              Только заказы с комментариями
            </x-checkbox>
          </div>
        </li>

        <!-- <li class="orders-filters__item orders-filters__item_content_reset">
          <reset-button
            block
            @click="onReset"
          >
            Очистить фильтры
          </reset-button>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import ShopFilter from '@/components/Interface/ShopFilter.vue'
// import Checkbox from '@/components/Interface/Checkbox.vue'
import Datepicker from '@/components/Interface/Datepicker.vue'
// import ResetButton from '@/components/Interface/ResetButton.vue'
import CalendarPeriods from '@/constants/calendar-periods'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('orders')
const { mapState: mapShopsState } = createNamespacedHelpers('marketplaces')
const { mapGetters } = createNamespacedHelpers('profile')

const today = new Date()

export default {
  components: {
    ShopFilter,
    // Checkbox,
    Datepicker
    // ResetButton
  },

  props: {
    status: {
      type: [String, Object],
      default: ''
    },
    selfPurchase: {
      type: [String, Object],
      default: ''
    },
    serviceType: {
      type: [String, Object],
      default: ''
    },
    onlyMemo: {
      type: Boolean,
      default: false
    },
    shop: {
      type: [String, Object],
      default: ''
    },
    marketplace: {
      type: Object,
      default: null
    },
    createdPeriod: {
      type: Object,
      default: () => ({
        start: today,
        end: today
      })
    },
    paymentPeriod: {
      type: Object,
      default: () => ({
        start: today,
        end: today
      })
    },
    sendPeriod: {
      type: Object,
      default: () => ({
        start: today,
        end: today
      })
    },
    disputePeriod: {
      type: Object,
      default: () => ({
        start: today,
        end: today
      })
    },
    filtersChanged: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      height: 'auto',
      animationLength: 300,
      toggleFiltersFlag: true,
      showFilters: true,
      search: null,
      shopDiscount: false,
      marketplaceDiscount: false,
      currentCreatedPeriod: CalendarPeriods.TODAY,
      currentPaymentPeriod: CalendarPeriods.TODAY,
      currentSendPeriod: CalendarPeriods.TODAY,
      currentDisputePeriod: CalendarPeriods.TODAY,

      SelfPurchaseList: [
        {
          id: 'ALL',
          label: 'Все заказы'
        },
        {
          id: 'true',
          label: 'Только самовыкупы'
        },
        {
          id: 'false',
          label: 'Все, кроме самовыкупа'
        }
      ],

      serviceTypeList: [
        {
          id: 'ALL',
          label: 'Все'
        },
        {
          id: 'fbo',
          label: 'FBO / FBW / FBY'
        },
        {
          id: 'fbs',
          label: 'FBS'
        },
        {
          id: 'dbs',
          label: 'DBS'
        }
      ]
    }
  },

  computed: {
    ...mapState(['xwayOrderStatuses']),
    ...mapShopsState(['shops']),
    ...mapGetters(['isUserAccessedToSection']),

    computedStatuses () {
      const statusOptions = this.xwayOrderStatuses.map(s => ({ label: s.name, id: s.value }))
      statusOptions.unshift({ label: 'Все', id: 'ALL' })

      return statusOptions
    },

    computedShops () {
      return this.shops.filter(shop => {
        return this.isUserAccessedToSection({
          section: 'access_orders',
          shopId: shop.id,
          $isDisabled: shop.is_paywalled_in_lkp
        })
      })
    },

    filtersMaxDate () {
      return today
    },

    textAsHTML () {
      return `<div class="mb-3">FBO / FBW / FBY – фулфилмент маркетплейса, доставка маркетплеса</div>
              <div class="mb-3">FBS – фулфилмент селлера, доставка маркетплейса</div>
              <div>DBS – фулфилмент селлера, доставка селлера</div>`
    }
  },

  mounted () {
    // this.toggleFilters()
  },

  methods: {
    onReset () {
      this.search = null
      this.$emit('reset')
    },
    toggleFilters () {
      if (!this.toggleFiltersFlag) return false
      this.toggleFiltersFlag = false
      const list = this.$refs.filterList
      const height = list.children[0].offsetHeight

      if (this.height === '0px') {
        this.height = `${height}px`

        setTimeout(() => {
          list.classList.remove('orders-filters__hidden')
          this.showFilters = true
          this.height = 'auto'
          this.toggleFiltersFlag = true
        }, this.animationLength)
      } else {
        this.height = `${height}px`
        list.classList.add('orders-filters__hidden')
        setTimeout(() => (this.height = '0px'))

        setTimeout(() => {
          this.showFilters = false
          this.toggleFiltersFlag = true
        }, this.animationLength)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .orders-filters
    padding 10px 0 20px

    &__collapsed
      transition .3s

    &__hidden
      overflow hidden

    &__list
      display flex
      flex-wrap wrap
      padding-top 10px
      margin-left -10px

    &__item
      flex-basis 210px
      margin-left 10px
      list-style none
      &_btn
        flex-basis auto
      &_checkbox
        flex-basis auto

    &__label
      height 18px

      &_content_checkboxes
        display flex
        align-items center
        justify-content space-between
        flex-basis 320px
        margin-left 20px

      &_content_reset
        display flex
        align-items flex-end

    &__checkbox
      transform translateY(50%)

.checkbox-block
  height 36px
</style>
