export const hints = {
  SELLER: {
    hint: 'Скидка по инициативе селлера'
  },
  PLATFORM: {
    hint: 'Скидка по инициативе маркетплейса'
  },
  OTHER: {
    hint: 'Инициатор скидки не установлен'
  }
}
