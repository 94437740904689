<template>
  <default-description
    identity="orders"
    :blocks="blocks"
    class="orders-description"
  >
    <template #title>
      <heading class="orders-description__heading">
        Заказы
      </heading>
    </template>
  </default-description>
</template>

<script>
import DefaultDescription from '@/components/Interface/Description.vue'
import Heading from '@/components/Common/Heading.vue'

export default {
  components: {
    DefaultDescription,
    Heading
  },

  data () {
    return {
      blocks: [{
        img: require('@/assets/images/svg/promotion-1.svg'),
        title: '1. Title',
        text: 'Text'
      }, {
        img: require('@/assets/images/svg/promotion-2.svg'),
        title: '2. Title',
        text: 'Text'
      }, {
        img: require('@/assets/images/svg/promotion-3.svg'),
        title: '3. Title',
        text: 'Text'
      }]
    }
  },

  created () {
    // TODO: remove when content is designed
    localStorage.setItem('seller_show_orders_description', 0)
  }
}
</script>

<style lang="stylus" scoped>
  .orders-description
    &__heading
      margin-bottom 0
</style>
