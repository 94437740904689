export const statuses = {
  PAY_SUCCESS: 'PAY_SUCCESS',
  NOT_PAY: 'NOT_PAY'
}

export const statusesText = {
  [statuses.PAY_SUCCESS]: 'Оплачен',
  [statuses.NOT_PAY]: 'Не оплачен'
}

export const payColors = {
  [statuses.PAY_SUCCESS]: 'success-lightest-2',
  [statuses.NOT_PAY]: 'orange-lighten-5'
}
