<template>
  <x-card
    class="order-preview-tabs"
    :class="{ 'order-preview-tabs__high': !isOrderFetched }"
    size="xl"
  >
    <template v-if="isOrderFetched">
      <tabs
        :tabs="tabs"
        :active="currentTab.path"
        class="mb-5"
        @click="onTabClick"
      />

      <component
        :is="currentTab.path"
        :order="order"
        @copied="$emit('copied')"
      />

      <template v-if="order.memo">
        <Comment v-if="currentTab.path === 'Info' || currentTab.path === 'Delivery'">
          <template #title>
            <div class="mb-1 font-weight-semibold">
              Комментарий к заказу
            </div>
          </template>
          <template #text>
            <div>{{ order.memo }}</div>
          </template>
        </Comment>
      </template>
    </template>

    <x-placeholder
      v-else
      icon="no-data"
    >
      <template #title>
        <div class="headline-4">
          Нет данных
        </div>
      </template>
    </x-placeholder>
  </x-card>
</template>

<script>
import Tabs from '@/components/Interface/Tabs.vue'
import Info from '@/components/Orders/PreviewTabs/Info/Info.vue'
import Delivery from '@/components/Orders/PreviewTabs/Delivery/Delivery.vue'
import Contacts from '@/components/Orders/PreviewTabs/Contacts/Contacts.vue'
import Payment from '@/components/Orders/PreviewTabs/Payment/Payment.vue'
import Commissions from '@/components/Orders/PreviewTabs/commissions/Commissions.vue'
import Marketing from '@/components/Orders/PreviewTabs/marketing/Marketing.vue'
import Comment from '@/components/Interface/Comment.vue'

export default {
  components: {
    Tabs,
    Info,
    Delivery,
    Contacts,
    Payment,
    Commissions,
    Marketing,
    Comment
  },
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      activeTab: 'Info',
      tabs: [{
        title: 'Общая информация',
        path: 'Info'
      }, {
        title: 'Доставка',
        path: 'Delivery'
      }, {
        title: 'Контактная информация',
        path: 'Contacts'
      }, {
        title: 'Информация  об оплате',
        path: 'Payment'
      }, {
        title: 'Комиссии',
        path: 'Commissions'
      }, {
        title: 'Маркетинг',
        path: 'Marketing'
      }]
    }
  },
  computed: {
    currentTab () {
      return this.tabs.find(t => t.path === this.activeTab)
    },
    isOrderFetched () {
      return Object.keys(this.order).length
    }
  },

  methods: {
    onTabClick (tab) {
      this.activeTab = tab

      const map = {
        Info: 'mmp_orders_order_info',
        Delivery: 'mmp_orders_order_ship',
        Contacts: 'mmp_orders_order_contactinfo',
        Payment: 'mmp_orders_order_payment',
        Commissions: 'mmp_orders_order_comission',
        Marketing: 'mmp_orders_order_marketing'
      }

      if (map[tab]) {
        this.$metrics.hit(map[tab], {
          order_id: this.order.id,
          marketplace_code: this.order.marketplace.code,
          shop_id: this.order.shop.id
        })
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.order-preview-tabs
  &__high
    height 385px
</style>
