<template>
  <div class="order-list">
    <div class="order-list__heading">
      <div class="order-list__heading-info">
        <template v-if="ordersCount">
          <span class="order-list__count">
            Найдено заказов: <strong>{{ ordersCount }}</strong>
          </span>
          <div class="order-list__amount ml-2 mr-2">
            на сумму
            <span class="font-weight-semibold">{{ formatPrice(ordersAmount) }} руб</span>
          </div>
          <checkbox
            v-model="unfoldAll"
            rounded
            class="order-list__checkbox"
          >
            Раскрыть все заказы
          </checkbox>
        </template>
      </div>

      <div v-if="ordersCount">
        <template v-if="selectedIds.length">
          <x-btn
            :loading="selfBuyoutLoading"
            @click="removeMark(selectedIds)"
          >
            Снять отметку о самовыкупе
          </x-btn>

          <x-btn
            :loading="selfBuyoutLoading"
            prepend="self-buyout"
            class="ml-3"
            @click="setMark(selectedIds)"
          >
            Отметить как самовыкуп
          </x-btn>
        </template>

        <x-btn
          v-if="!selectedIds.length && validTabs"
          ref="selfBuyoutBtn"
          outline
          prepend="self-buyout"
          metrics="mmp_orders_selfpurchase"
          @click="showSelfBuyoutByFile"
        >
          Самовыкуп
        </x-btn>

        <x-btn
          color="gray"
          prepend="upload"
          class="order-list__export ml-3"
          :class="{ 'order-list__export_notext': selectedIds.length }"
          :loading="downloadPending"
          @click="$emit('download-click')"
        >
          {{ selectedIds.length ? '' : 'Экспортировать' }}
        </x-btn>
      </div>
    </div>

    <grid
      class="order-list__table"
      auto
      no-hover-bg
      unfoldable
      :selectable="!!orders.length"
      :cells="computedOrders"
      :columns="computedColumns"
      select-by="order_id"
      :checked-cells-ids="selectedIds"
      @cell-checked="selectOrder"
      @check-all="selectAll"
    >
      <template #id="{ row }">
        <div class="d-flex align-center">
          <x-img
            :src="row.order_items[0] && row.order_items[0].image_url ? row.order_items[0].image_url : ''"
          />

          <div class="p-2">
            <x-link
              class="font-weight-semibold"
              :to="{ name: 'order', params: { id: row.id } }"
            >
              {{ row.order_id }}
            </x-link>
            <div class="gray-darker--text caption mt-1">
              Создан:
              <span class="product-meta__value">
                {{ row.gmt_create ? formatDate(row.gmt_create, 'DD.MM.YYYY HH:mm') : '-' }}
              </span>
            </div>

            <div
              v-if="row.order_items.length"
              class="d-flex align-center primary--text font-weight-semibold unfoldable-btn"
              @click="$set(row, 'isUnfold', !row.isUnfold)"
            >
              Состав заказа
              <x-icon
                :name="row.isUnfold ? 'dropdown-up' : 'dropdown-down'"
                size="xxs"
              />
            </div>
          </div>
        </div>
      </template>

      <template #unfold="{ row }">
        <grid
          auto
          bordered
          no-hover-bg
          dropdown
          :cells="row.order_items"
          :columns="productColumns"
          class="order-list__product-grid"
          :class="{ 'order-list__with_memo': row.memo }"
        >
          <template #product_id="{ row: product }">
            <div class="order-list__product-meta">
              <x-img :src="product.image_url" />
              <div class="pl-2 pr-2">
                <div class="d-flex align-center">
                  <div class="mr-1">
                    {{ product.id }}
                  </div>
                  <x-link
                    v-if="product.link"
                    :href="product.link"
                    class="order-list__product-link"
                  >
                    <x-icon
                      name="link"
                      size="xs"
                    />
                  </x-link>
                </div>
                <div>
                  <div
                    v-if="product.sku_code"
                    v-clipboard:copy="(product.sku_code).toString()"
                    class="caption gray-darker--text d-inline-flex align-center copy-text"
                    @click="copyText"
                  >
                    <span>Арт. МП: {{ product.sku_code }}</span>
                    <x-icon
                      name="copy"
                      size="xxs"
                      class="ml-1"
                    />
                  </div>
                </div>
                <div>
                  <div
                    v-if="product.internal_id"
                    v-clipboard:copy="(product.internal_id).toString()"
                    class="caption gray-darker--text d-inline-flex align-center copy-text"
                    @click="copyText"
                  >
                    <span>Арт. внут.: {{ product.internal_id }}</span>
                    <x-icon
                      name="copy"
                      size="xxs"
                      class="ml-1"
                    />
                  </div>
                </div>
                <div>
                  <div
                    v-if="product.barcode"
                    v-clipboard:copy="(product.barcode).toString()"
                    class="caption gray-darker--text d-inline-flex align-center copy-text"
                    @click="copyText"
                  >
                    <span>Штрихкод: {{ product.barcode }}</span>
                    <x-icon
                      name="copy"
                      size="xxs"
                      class="ml-1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template #price="{ price }">
            {{ formatPrice(price) }} руб
          </template>

          <template #cost="{ cost }">
            {{ formatPrice(cost) }} руб
          </template>

          <template #name="{ name }">
            <div class="order-list__product-name">
              {{ name ? name : '' }}
            </div>
          </template>

          <template #brand="{ brand }">
            {{ brand ? brand : '—' }}
          </template>

          <template #append-item="{ cell: { memo } }">
            <div
              v-if="memo"
              class="order-memo"
            >
              <Comment>
                <template #title>
                  <div class="mb-1 font-weight-semibold">
                    Комментарий к заказу
                  </div>
                </template>
                <template #text>
                  <div>{{ memo }}</div>
                </template>
              </Comment>
            </div>
          </template>

          <template #total-values>
            <tr class="order-list__total font-weight-bold grid__cell grid__cell_viewtype_numbers">
              <td
                class="total-text"
                colspan="5"
              >
                Итого
              </td>
              <td>
                <div class="grid__cell-in">
                  <div>{{ totalPrice(row.order_items, 'total_product_amount') }} руб</div>
                </div>
              </td>
            </tr>
          </template>
        </grid>
      </template>

      <template #cell-memo="{ row }">
        <tr
          v-if="row.memo"
          class="p-6"
        >
          <td />
          <td
            colspan="7"
            class="order-cell-memo"
          >
            <Comment>
              <template #title>
                <div class="mb-1 font-weight-semibold">
                  Комментарий к заказу
                </div>
              </template>
              <template #text>
                <div>{{ row.memo }}</div>
              </template>
            </Comment>
          </td>
        </tr>
      </template>

      <template #shop="{ shop }">
        <div class="mb-1">
          {{ shop.name }}
        </div>
        <div
          v-if="shop.marketplace"
          class="d-flex align-center"
        >
          <x-icon
            :name="getMpIcon(shop.marketplace.code)"
            size="xxs"
          />
          <div class="gray-darker--text caption ml-1">
            {{ shop.marketplace.name }}
          </div>
        </div>
      </template>

      <template #xway_status="{ row }">
        <xway-status
          :status="row.xway_status"
          class="mb-1"
        />
        <div
          v-if="showDate(row.shipment_date)"
          class="warning--text caption font-weight-semibold mb-2"
        >
          До отправки:<br> {{ getShipmentDiff(row.shipment_date) }}
        </div>
        <div
          v-if="row.self_buyout"
          class="d-flex align-center"
        >
          <x-icon
            name="self-buyout"
            size="xs"
            color="purple-accent-4"
          />
          <div class="ml-1 caption purple-accent-4--text font-weight-semibold order-list__buyout">
            САМОВЫКУП
          </div>
        </div>
      </template>

      <template #service_type="{ row }">
        <div class="mb-1">
          {{ row.logistics_type }}
        </div>
        <div class="caption gray-darker--text">
          {{ row.service_type ? row.service_type.toUpperCase() : '' }}
        </div>
      </template>

      <template #delivery_price="{ delivery_price }">
        <span :class="{ 'font-weight-bold': delivery_price }">
          {{ formatPrice(delivery_price) }} руб
        </span>
      </template>

      <template #pay_amount="{ pay_amount }">
        <strong class="order-list-nowrap">{{ formatPrice(pay_amount) }} руб</strong>
      </template>

      <template #action_menu="{ row }">
        <grid-row-action-menu
          :actions="actionMenuList(row.self_buyout)"
          :row="row"
        />
      </template>
    </grid>

    <no-shops
      v-if="!shops.length"
      data="по заказам"
    />

    <x-placeholder
      v-else-if="!orders.length"
      icon="table-empty"
      desc="Информация в таблице отсутствует"
    />
    <transition name="fade">
      <Copied v-if="showCopyDesc" />
    </transition>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Checkbox from '@/components/Interface/Checkbox.vue'
import Grid from '@/components/Interface/Grid.vue'
import NoShops from '@/components/Interface/NoShops.vue'
import XwayStatus from '@/components/Common/XwayStatus/XwayStatus.vue'
import GridRowActionMenu from '@/components/Interface/GridRowActionMenu.vue'
import formatPrice from '@/utils/price-formatter'
import formatDate, { getDiff } from '@/utils/date-formatter'
import columns, { productColumns, actionsSelfBuyout } from '@/constants/orders'
import marketplaceCodes from '@/constants/marketplace-codes'
import marketplaceIcons from '@/constants/marketplace-icons'
import { statusColors } from '@/constants/orders-statuses'
import SelfBuyout from './modals/SelfBuyout.vue'
import SelfBuyoutByFile from './modals/SelfBuyoutByFile.vue'
import OrdersSuccessModal from './modals/OrdersSuccessModal.vue'
import Comment from '@/components/Interface/Comment.vue'
import Copied from '@/components/Interface/Сopied.vue'

const { mapState, mapActions } = createNamespacedHelpers('orders')
const { mapState: mapShopsState } = createNamespacedHelpers('marketplaces')

export default {
  components: {
    Checkbox,
    Grid,
    NoShops,
    XwayStatus,
    GridRowActionMenu,
    Comment,
    Copied
  },

  props: {
    orders: {
      type: Array,
      default: () => []
    },
    marketplace: {
      type: Object,
      default: () => ({})
    },
    selectedIds: {
      type: Array,
      default: () => []
    },
    downloadPending: Boolean
  },

  data () {
    return {
      showCopyDesc: false,
      productColumns,
      unfoldAll: false,
      statusColors,
      selfBuyoutLoading: false,
      actions: [
        {
          label: 'Подробности заказа',
          cb: cell => this.toOrderPage(cell.id)
        }
      ]
    }
  },

  inject: ['fetchData'],

  watch: {
    unfoldAll (val) {
      if (val) {
        this.$metrics.hit('mmp_orders_collapse_all')
      } else {
        this.orders.forEach(el => (el.isUnfold = false))
      }
    }
  },

  computed: {
    ...mapState(['ordersCount', 'ordersAmount']),
    ...mapShopsState(['shops']),

    computedOrders () {
      return this.unfoldAll ? this.orders.map(o => ({ ...o, isUnfold: true })) : this.orders
    },

    isMarketplaceAli () {
      return this.marketplace.code === marketplaceCodes.ALI
    },

    computedColumns () {
      return this.isMarketplaceAli ? columns : columns.filter(c => c.key !== 'logistics_type')
    },

    validTabs () {
      return this.marketplace.id === 'ALL' || this.marketplace.code === 'ozon'
    }
  },

  methods: {
    ...mapActions(['setMarkSelfPurchase', 'removeMarkSelfPurchase']),
    formatDate,
    formatPrice,

    copyText () {
      this.showCopyDesc = true

      setTimeout(() => {
        this.showCopyDesc = false
      }, 2000)
    },

    toOrderPage (cellId) {
      this.$router.push(`/order/${cellId}`)
    },

    selectOrder (cell) {
      const checkedId = this.selectedIds.findIndex(id => cell.order_id === id)

      if (~checkedId) {
        this.selectedIds.splice(checkedId, 1)
      } else {
        this.selectedIds.push(cell.order_id)
      }
    },

    selectAll (checked) {
      if (!checked) {
        const ids = this.computedOrders.map(item => item.order_id)
        this.$emit('remove-all-selected', ids)
        return
      }

      this.computedOrders.forEach(item => {
        if (this.selectedIds.includes(item.order_id)) return
        this.selectedIds.push(item.order_id)
      })
    },

    getShipmentDiff (shipDate) {
      const diff = getDiff(new Date(), shipDate)
      const days = Math.floor(diff.asDays())
      return `${days} дн ${diff.hours()} ч ${diff.minutes()} мин`
    },

    showShipmentDiff (order) {
      return order.shipment_date && order.show_send_goods_timer
    },

    getMpIcon (code) {
      return marketplaceIcons[code]?.icon
    },

    showDate (date) {
      const day = this.getShipmentDiff(date).split(' ')[0]
      return (+day) >= 0
    },

    actionMenuList (selfBuyout) {
      return this.actions.concat([
        {
          label: 'Отметить как самовыкуп',
          cb: cell => {
            this.selectedIds.splice(0)
            this.setMark([cell.order_id])
          },
          self_buyout: false
        },
        {
          label: 'Снять отметку о самовыкупе',
          cb: cell => {
            this.selectedIds.splice(0)
            this.removeMark([cell.order_id])
          },
          self_buyout: true
        }
      ].filter(el => el.self_buyout === selfBuyout))
    },

    removeMark (selectedIds) {
      const props = {
        selectedIds,
        action: actionsSelfBuyout.REMOVE_MARK
      }

      this.$dialog.show(SelfBuyout, {
        props: () => props,
        on: {
          async onSubmit () {
            this.selfBuyoutLoading = true
            await this.removeMarkSelfPurchase({ order_id: selectedIds })
            this.selfBuyoutLoading = false
            this.$emit('update-list', actionsSelfBuyout.REMOVE_MARK)
          }
        }
      })
    },

    setMark (selectedIds) {
      const props = {
        selectedIds,
        action: actionsSelfBuyout.SET_MARK
      }

      this.$dialog.show(SelfBuyout, {
        props: () => props,
        on: {
          async onSubmit () {
            this.selfBuyoutLoading = true
            await this.setMarkSelfPurchase({ order_id: selectedIds })
            this.selfBuyoutLoading = false
            this.$emit('update-list', actionsSelfBuyout.SET_MARK)
          }
        }
      })
    },
    showSelfBuyoutByFile () {
      this.$dialog.show(SelfBuyoutByFile, {
        on: {
          uploadFile () {
            this.fetchData()
            this.showSuccessModal()
          }
        }
      })
    },
    showSuccessModal () {
      this.$dialog.show(OrdersSuccessModal, {
        on: {
          addSelfBuyout () {
            this.showSelfBuyoutByFile()
          }
        }
      })
    },
    totalPrice (list, key) {
      return this.formatPrice(list.reduce((acc, item) => (acc + +(item[key])), 0))
    }
  }
}
</script>

<style lang="stylus" scoped>
  .order-list
    &__heading
      display flex
      align-items center
      justify-content space-between
      margin-bottom 33px

    &__with_memo
      & >>> .grid-container__table
        padding-bottom 10px

    &__table
      overflow-x auto

    &__heading-info
      display flex
      align-items center

    &__count
      font-size 14px

    &__buyout
      transform translateY(1px)

    &__amount
      font-size 14px
      background: $colors.success-lightest-2
      padding 2px 8px
      border-radius 22px

    &__export
      &_notext
        & >>> .x-icon
          margin 0

    &__image
      width 70px
      height 70px
      border 1px solid #e5e5e9
      border-radius 4px
      object-fit contain

    &__meta
      margin-left 8px

    &__marketplace
      padding 0

    &__delivery
      padding 2px 8px
      margin-bottom 4px
      border-radius 20px
      background-color #fcebeb

    &__product-meta
      display flex

    &__total
      border-top 1px solid #d7d6d6
      background $colors.gray-lightest-2

    &__product-link
      color: $colors.gray-dark
      &:hover
        color: $colors.primary

    & .total-text
      padding 10px

    &__product-name
      display: -webkit-box;
      -webkit-line-clamp: 4;
      overflow: hidden;
      -webkit-box-orient: vertical;

    & .order-list-nowrap
      white-space nowrap

    // & >>> .grid-container__table
    //   padding 10px 0 20px

    & .x-badge
      white-space nowrap

    & >>> .grid__cell_unfoldable
      width 100%
      padding-left 10px

.order-memo
  padding 5px 10px 10px

.order-cell-memo
  padding 0 0 20px 10px

.copy-text
  cursor pointer
  & >>> .x-icon
    visibility hidden
  &:hover
    >>> .x-icon
      visibility visible

.unfoldable-btn
  cursor pointer
  margin-top 6px
  display inline-flex
  user-select none
  & >>> .x-icon
    margin-left 2px

.fade-enter-active, .fade-leave-active
  transition opacity .3s

.fade-enter, .fade-leave-to
  opacity 0

</style>
