<template>
  <div class="row">
    <property-list
      :properties="columns.firstColumn"
      :item="order"
      class="col col-4"
    >
      <template #shop="{ item, shop }">
        <marketplace
          :name="item.marketplace.code"
          :shop="shop.name"
          full
        />
      </template>

      <template #status="{ status }">
        <badge
          v-if="status"
          :status="status"
        />
        <template v-else>
          -
        </template>
      </template>

      <template #date="{ date }">
        {{ date ? formatDate(date) : '-' }}
      </template>

      <template #fbo="{ fbo }">
        <div
          v-if="fbo"
          class="d-flex align-center"
        >
          {{ fbo.toUpperCase() }}
          <x-icon
            v-hint="{
              popperClass: 'popper',
              placement: 'top',
              content: textAsHTML,
              triggers: ['hover'],
              delay: 100,
              html: true
            }"
            class="ml-1"
            name="info"
            size="xs"
            color="gray"
          />
        </div>
        <div v-else>
          -
        </div>
      </template>
    </property-list>

    <property-list
      :properties="columns.secondColumn"
      :item="order"
      class="col col-4"
    >
      <template #xway_status="{ xway_status }">
        <xway-status :status="xway_status" />
      </template>

      <template #fund_status="{ fund_status }">
        <pay-status :status="fund_status" />
      </template>

      <template #date="{ date }">
        {{ formatDate(date) || '-' }}
      </template>

      <template #shipment_date="{ shipment_date }">
        <x-badge
          v-if="shipment_date && showShipmentDate(shipment_date)"
          color="deep-orange-lighten-5"
        >
          {{ getShipmentText(shipment_date) }}
        </x-badge>
        <template v-else>
          -
        </template>
      </template>
    </property-list>
  </div>
</template>

<script>
import PropertyList from '@/components/Interface/PropertyList.vue'
import Marketplace from '@/components/Common/Marketplace.vue'
import Badge from '@/components/Common/Badge.vue'
import XwayStatus from '@/components/Common/XwayStatus/XwayStatus'
import PayStatus from '@/components/Orders/PreviewTabs/PayStatus/PayStatus.vue'
import columns from './columns'
import formatDate from '@/utils/date-formatter'
import { showShipmentDate, getShipmentText } from '../../shared'

export default {
  components: {
    PropertyList,
    Marketplace,
    Badge,
    XwayStatus,
    PayStatus
  },
  props: {
    order: {
      type: Object,
      default: () => ({})
    },
    marketplace: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      columns
    }
  },
  computed: {
    textAsHTML () {
      return `<div class="mb-3">FBO / FBW / FBY – фулфилмент маркетплейса, доставка маркетплеса</div>
              <div class="mb-3">FBS – фулфилмент селлера, доставка маркетплейса</div>
              <div>DBS – фулфилмент селлера, доставка селлера</div>`
    }
  },
  methods: {
    formatDate,
    showShipmentDate,
    getShipmentText
  }
}
</script>
