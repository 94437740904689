<template>
  <component
    :is="componentName"
    :order="order"
    :marketplace="marketplace"
  />
</template>

<script>
import DefaultPayment from './components/DefaultPayment/DefaultPayment.vue'
import OzonPayment from './components/OzonPayment/OzonPayment.vue'
import MARKETPLACES from '@/constants/marketplace-codes'

export default {
  components: {
    DefaultPayment,
    OzonPayment
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    marketplace () {
      return this.order.marketplace.code
    },
    componentName () {
      switch (this.marketplace) {
        case MARKETPLACES.OZON:
          return 'OzonPayment'

        default:
          return 'DefaultPayment'
      }
    }
  }
}
</script>
