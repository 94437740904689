
export default [{
  name: 'ID заказа',
  sortable: true,
  unfoldable: true,
  key: 'id',
  type: 'id',
  styles: { minWidth: '230px' }
}, {
  name: 'Магазин на маркетплейсе',
  key: 'shop',
  type: 'shop',
  sortable: true,
  styles: { minWidth: '150px' }
}, {
  name: 'Способ доставки',
  key: 'service_type',
  type: 'service_type',
  headStyle: { width: '115px' },
  styles: { minWidth: '150px' }
}, {
  name: 'Статус',
  key: 'xway_status',
  type: 'xway_status',
  sortable: true,
  headStyle: { textAlign: 'left' },
  styles: { minWidth: '100px', textAlign: 'left' }
}, {
  name: 'Cтоимость доставки',
  key: 'delivery_price',
  type: 'delivery_price',
  styleType: 'numbers'
}, {
  name: 'Сумма заказа',
  key: 'pay_amount',
  type: 'pay_amount',
  styleType: 'numbers',
  sortable: true
}, {
  name: '',
  type: 'action_menu',
  styles: { width: '40px' }
}]

export const productColumns = [{
  name: 'ID товара',
  key: 'product_id',
  type: 'product_id',
  styles: { minWidth: '200px' }
}, {
  name: 'Наименование',
  key: 'name',
  type: 'name',
  styles: { display: 'block', textAlign: 'left', minWidth: '200px', maxWidth: '300px' }
}, {
  name: 'Бренд',
  key: 'brand',
  type: 'brand',
  styles: { display: 'block', minWidth: '100px' }
}, {
  name: 'Кол-во',
  styleType: 'numbers',
  key: 'product_count',
  styles: { display: 'block', minWidth: '100px' }
}, {
  name: 'Цена',
  styleType: 'numbers',
  key: 'product_unit_price_amount',
  type: 'price',
  styles: { display: 'block', minWidth: '100px' }
}, {
  name: 'Стоимость',
  styleType: 'numbers',
  key: 'total_product_amount',
  type: 'cost',
  styles: { display: 'block', minWidth: '100px' }
}]

export const actionsSelfBuyout = {
  REMOVE_MARK: 'REMOVE_MARK',
  SET_MARK: 'SET_MARK'
}

export const selfBuyoutMarketplacesByFile = [
  {
    id: 'ozon',
    icon: 'ozon--full',
    file_url: `${process.env.VUE_APP_API}/static/xlsx_templates/ozon_self_buyout.xlsx`
  }
  // {
  //   id: 'wildberries',
  //   icon: 'wildberries--full'
  // }
]

export const validFiles = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]
