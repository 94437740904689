export default {
  firstColumn: [{
    name: 'Сумма до скидок',
    key: 'old_sum',
    type: 'total'
  }, {
    name: 'Сумма скидок',
    key: 'discount_amount_sum',
    type: 'total'
  }, {
    name: 'Сумма продажи',
    key: 'rrc_sum',
    type: 'total'
  }, {
    name: 'Сумма комиссий маркетплейса',
    key: '', // ?
    type: 'total'
  }, {
    name: 'Выплата продавцу',
    key: 'payout',
    type: 'total'
  }],
  secondColumn: [{
    name: 'Статус оплаты',
    key: 'fund_status',
    type: 'status'
  }, {
    name: 'Способ оплаты',
    key: 'payment_type'
  }, {
    name: 'Валюта оплаты',
    key: 'pay_currency'
  }, {
    name: 'Дата оплаты',
    key: 'gmt_pay_time',
    type: 'date'
  }]
}
