<template>
  <div class="order">
    <header class="order__header d-flex align-center">
      <div
        class="order__back"
        @click="toPrevPage"
      >
        <x-icon
          :name="'chevron-left'"
          size="s"
        />
      </div>
      <heading class="order__heading d-flex align-center">
        Заказ: {{ order.order_id }}
        <x-btn
          v-clipboard:copy="order.order_id"
          size="s"
          icon="copy"
          ghost
          class="ml-1"
          @click="onCopy"
        />
      </heading>

      <refresh-button
        ghost
        @click="getOrderInfo"
      />
    </header>

    <div
      v-if="isLoading"
      class="order__loading-preview"
    >
      <loader class="order__loader" />
    </div>

    <template v-else>
      <order-preview-tabs
        :order="order"
        class="order__tabs"
        @copied="onCopy"
      />

      <order-products
        v-if="isData"
        :order="order"
        @copied="onCopy"
      />
    </template>
    <transition name="fade">
      <Copied v-if="showCopyDesc" />
    </transition>
  </div>
</template>

<script>
import Heading from '@/components/Common/Heading.vue'
import RefreshButton from '@/components/Interface/RefreshButton.vue'
import OrderPreviewTabs from '@/components/Orders/PreviewTabs/Index.vue'
import Loader from '@/components/Common/Loader.vue'
import OrderProducts from '@/components/Orders/OrderProducts.vue'
import Copied from '@/components/Interface/Сopied.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapState } = createNamespacedHelpers('orders')

export default {
  components: {
    Heading,
    RefreshButton,
    OrderPreviewTabs,
    Loader,
    OrderProducts,
    Copied
  },

  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },

  data () {
    return {
      showCopyDesc: false,
      isLoading: false
    }
  },

  watch: {
    order () {
      this.$metrics.hit('mmp_orders_order_info', {
        order_id: this.order.id,
        marketplace_code: this.order.marketplace?.code,
        shop_id: this.order.shop?.id
      })
    }
  },

  computed: {
    ...mapState(['order']),

    isData () {
      return Object.keys(this.order).length
    }
  },

  created () {
    this.getOrderInfo()
  },

  methods: {
    ...mapActions(['getOrder']),

    onCopy () {
      this.showCopyDesc = true
      setTimeout(() => {
        this.showCopyDesc = false
      }, 2000)
    },

    async getOrderInfo () {
      this.isLoading = true
      await this.getOrder(this.id)
      this.isLoading = false
    },
    toPrevPage () {
      history.state ? this.$router.go(-1) : this.$router.push({ name: 'orders' })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .order
    &__header
      display flex
      margin-bottom 23px

    &__back
      color: $colors.gray-dark
      width 36px
      height 36px
      border-radius 5px
      display flex
      align-items center
      justify-content center
      cursor pointer
      transition .2s
      margin-right 10px
      flex-shrink 0
      &:hover
        color: $colors.gray-darkest-2
        background $colors.gray-lighter

    &__heading
      margin-bottom 0

    &__loading-preview
      position relative
      height 385px
      border-radius 8px
      box-shadow 0px 6px 16px rgba(5, 2, 55, 0.04)
      background-color #fdfdfd

    &__loader
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)

    &__tabs
      margin-bottom 30px

.fade-enter-active, .fade-leave-active
  transition opacity .3s

.fade-enter, .fade-leave-to
  opacity 0

</style>
