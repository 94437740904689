export default Object.freeze({
  ozon: {
    icon: 'ozon'
  },
  ali: {
    icon: 'aliexpress'
  },
  beru: {
    icon: 'yandex-market'
  },
  wildberries: {
    icon: 'wildberries'
  },
  goods: {
    icon: 'sber-mega-market'
  }
})
