<template>
  <component
    :is="componentName"
    :order="order"
    :marketplace="marketplace"
  />
</template>

<script>
import DefaultContacts from './components/DefaultContacts/DefaultContacts.vue'
import OzonContacts from './components/OzonContacts/OzonContacts.vue'
import MARKETPLACES from '@/constants/marketplace-codes'

export default {
  components: {
    DefaultContacts,
    OzonContacts
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    marketplace () {
      return this.order.marketplace.code
    },
    componentName () {
      switch (this.marketplace) {
        case MARKETPLACES.OZON:
          return 'OzonContacts'

        default:
          return 'OzonContacts'
      }
    }
  }
}
</script>
