<template>
  <div class="orders">
    <orders-description class="orders__description" />
    <orders-tabs />
  </div>
</template>

<script>
import OrdersTabs from '@/components/Orders/OrdersTabs.vue'
import OrdersDescription from '@/components/Orders/Description.vue'

export default {
  components: {
    OrdersTabs,
    OrdersDescription
  }
}
</script>

<style lang="stylus" scoped>
  .orders
    &__description
      margin-bottom 30px
</style>
