<template>
  <x-badge
    v-if="status"
    :color="computedColor"
  >
    {{ computedStatus }}
  </x-badge>
</template>

<script>
import { statusesText, payColors } from './shared'

export default {
  props: {
    status: {
      type: String,
      default: null
    }
  },
  computed: {
    computedStatus () {
      return statusesText[this.status] || this.status
    },
    computedColor () {
      return payColors[this.status] || null
    }
  }
}
</script>
