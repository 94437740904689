<template>
  <x-dialog
    cancel-text="Закрыть"
    no-submit
  >
    <template #title>
      <div class="title mb-2">
        Определить самовыкупы через файл
      </div>
    </template>

    <template #description>
      <div class="mb-2">
        Здесь вы можете массово отметить заказы как «Самовыкупы», загрузив файл
      </div>
      <x-link
        :href="SELF_REDEMPTION.link"
        primary
        metrics="mmp_orders_selfpurchase_guide"
      >
        {{ SELF_REDEMPTION.name }}
      </x-link>
      <div class="marketplaces-row mt-6">
        <marketplace-item
          v-for="marketplace in selfBuyoutMarketplacesByFile"
          :key="marketplace.id"
          :loading="loading"
          :marketplace="marketplace"
          @upload="onUploadFile"
        />
      </div>
    </template>
  </x-dialog>
</template>

<script>
import { selfBuyoutMarketplacesByFile } from '@/constants/orders'
import MarketplaceItem from '@/components/Orders/MarketplaceItem.vue'
import convertToFormData from '@/utils/form-data'
import { createNamespacedHelpers } from 'vuex'
import { HELP_DOCS } from '@/constants/links'

const { mapActions } = createNamespacedHelpers('orders')

export default {
  components: {
    MarketplaceItem
  },
  data () {
    return {
      loading: false,
      selfBuyoutMarketplacesByFile,
      SELF_REDEMPTION: HELP_DOCS.SELF_REDEMPTION
    }
  },

  methods: {
    ...mapActions(['setMarkSelfPurchaseByFile']),

    async onUploadFile (file) {
      this.loading = true
      await this.setMarkSelfPurchaseByFile(convertToFormData({ file }))
      this.loading = false

      this.$metrics.hit('mmp_orders_selfpurchase_download')
      this.$emit('uploadFile')
    }
  }
}
</script>

<style lang="stylus" scoped>
.marketplaces-row
  display grid
  grid-template-columns 1fr
  grid-gap 12px

.marketplace
  display flex
  flex-direction column
  align-items center
  border-radius 8px
  padding 34px 24px 24px
  border: 1px dashed $colors.gray
  width 100%

  &__title
    margin-bottom 15px

  &__title svg
    width 90px
    height 25px

  &__link
    text-align center

  &__file-upload
    margin-top auto

.file-name
  text-align center
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
</style>
