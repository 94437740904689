var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('property-list',{staticClass:"col col-4",attrs:{"properties":_vm.columns.firstColumn,"item":_vm.order},scopedSlots:_vm._u([{key:"shop",fn:function(ref){
var item = ref.item;
var shop = ref.shop;
return [_c('marketplace',{attrs:{"name":item.marketplace.code,"shop":shop.name,"full":""}})]}},{key:"status",fn:function(ref){
var status = ref.status;
return [(status)?_c('badge',{attrs:{"status":status}}):[_vm._v(" - ")]]}},{key:"date",fn:function(ref){
var date = ref.date;
return [_vm._v(" "+_vm._s(date ? _vm.formatDate(date) : '-')+" ")]}},{key:"fbo",fn:function(ref){
var fbo = ref.fbo;
return [(fbo)?_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(fbo.toUpperCase())+" "),_c('x-icon',{directives:[{name:"hint",rawName:"v-hint",value:({
            popperClass: 'popper',
            placement: 'top',
            content: _vm.textAsHTML,
            triggers: ['hover'],
            delay: 100,
            html: true
          }),expression:"{\n            popperClass: 'popper',\n            placement: 'top',\n            content: textAsHTML,\n            triggers: ['hover'],\n            delay: 100,\n            html: true\n          }"}],staticClass:"ml-1",attrs:{"name":"info","size":"xs","color":"gray"}})],1):_c('div',[_vm._v(" - ")])]}}])}),_c('property-list',{staticClass:"col col-4",attrs:{"properties":_vm.columns.secondColumn,"item":_vm.order},scopedSlots:_vm._u([{key:"xway_status",fn:function(ref){
          var xway_status = ref.xway_status;
return [_c('xway-status',{attrs:{"status":xway_status}})]}},{key:"fund_status",fn:function(ref){
          var fund_status = ref.fund_status;
return [_c('pay-status',{attrs:{"status":fund_status}})]}},{key:"date",fn:function(ref){
          var date = ref.date;
return [_vm._v(" "+_vm._s(_vm.formatDate(date) || '-')+" ")]}},{key:"shipment_date",fn:function(ref){
          var shipment_date = ref.shipment_date;
return [(shipment_date && _vm.showShipmentDate(shipment_date))?_c('x-badge',{attrs:{"color":"deep-orange-lighten-5"}},[_vm._v(" "+_vm._s(_vm.getShipmentText(shipment_date))+" ")]):[_vm._v(" - ")]]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }