<template>
  <div class="commissions">
    <div v-if="rows.length">
      <div
        v-for="key in rows"
        :key="key"
        class="commissions__item"
      >
        <div class="commissions__label">
          {{ order.commissions[key].description }}
        </div>
        <div
          class="commissions__value"
          :class="{ commissions__value_dashed: order.commissions[key].items.length }"
        >
          {{ formatPrice(order.commissions[key].amount) }} руб
          <div
            v-if="order.commissions[key].items.length"
            class="desdcription p-3"
          >
            <div
              v-for="descItem in order.commissions[key].items"
              :key="descItem.date"
              class="desdcription__item"
            >
              <div class="desdcription__date">
                {{ formatDate(descItem.operation_date) }}
              </div>
              <div class="desdcription__value d-flex">
                <div class="desdcription__price">
                  {{ formatPrice(descItem.amount) }}
                </div>
                <div>&nbsp;руб</div>
              </div>
              <div>
                <div class="desdcription__tex">
                  {{ descItem.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <x-link
        class="d-inline-flex align-center"
        primary
        :href="analyticsURL"
        metrics="mmp_orders_order_marketing_link_reports"
      >
        <x-icon
          class="mr-1"
          name="list"
          size="s"
        />
        Отчёт с детализацией комиссии по товарам
      </x-link> -->
    </div>
    <x-placeholder
      v-else
      icon="no-data"
    >
      <template #title>
        <div class="headline-4">
          Нет данных
        </div>
      </template>
      <template #desc>
        Информация о комиссиях может поступить позднее
      </template>
    </x-placeholder>
  </div>
</template>

<script>
import formatPrice from '@/utils/price-formatter'
import dateFormat from '@/utils/date-formatter'

export default {
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    analyticsURL () {
      return `${process.env.VUE_APP_INTERNAL_ANALYTICS_URL}/reports`
    },
    rows () {
      return this.order.commissions ? Object.keys(this.order.commissions) : {}
    }
  },
  methods: {
    formatPrice,
    formatDate (date) {
      return dateFormat(date, 'DD.MM.YYYY', { utc: '' })
    }
  }
}
</script>

<style lang="stylus" scoped>
.commissions
  &__item
    display flex
    align-items center
    justify-content space-between
    max-width 500px
    margin-bottom 14px
    &:last-of-type
      margin-bottom 20px

  &__value
    position relative
    white-space nowrap
    &:hover
      .desdcription
        visibility visible
        opacity 1

  &__label
    color #9b9ba3
    white-space nowrap
    text-overflow ellipsis
    overflow hidden
    padding-right 15px

.desdcription
  position absolute
  background #FFFFFF
  box-shadow 0px 6px 16px rgba(5,2,55,.12)
  border-radius 8px
  z-index 1
  bottom calc(100% + 10px)
  left 50%
  transform translateX(-50%)
  transition .2s
  visibility hidden
  opacity 0

  &__date
    color $colors.gray-darker

  &__item
    display grid
    grid-template-columns 80px 90px auto
    grid-gap 12px
    &:not(:last-of-type)
      margin-bottom 4px

  &__price
    white-space nowrap
    text-overflow ellipsis
    overflow hidden

  &__tex
    white-space nowrap
    text-overflow ellipsis
    overflow hidden
    max-width 400px

.commissions__value_dashed
  border-bottom 2px dotted $colors.gray-darkest-2

</style>
