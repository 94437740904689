import { getDiff } from '@/utils/date-formatter'

export const getShipmentDiff = shipDate => {
  return getDiff(new Date(), shipDate)
}

export const showShipmentDate = shipDate => getShipmentDiff(shipDate).asMilliseconds() >= 0

export const getShipmentText = shipDate => {
  const diff = getShipmentDiff(shipDate)
  const days = Math.floor(diff.asDays())

  return `${days} дн ${diff.hours()} ч ${diff.minutes()} мин`
}
