export default {
  firstColumn: [{
    name: 'Способ доставки',
    key: 'logistics_type'
  }, {
    name: 'Служба доставки',
    key: 'logistics_service_name'
  }, {
    name: 'Дата отправления',
    key: 'sender_tms',
    type: 'date'
  }, {
    name: 'Трек номер',
    key: 'track_number',
    type: 'track_number'
  }, {
    name: 'Трек линк',
    key: 'track_url',
    type: 'track_url'
  }],
  secondColumn: [{
    name: 'Статус доставки',
    key: 'delivery_status',
    type: 'status'
  }, {
    name: 'Стоимость доставки',
    key: 'delivery_price',
    type: 'delivery_price'
  }, {
    name: 'Склад',
    key: 'warehouse',
    type: 'warehouse'
  }, {
    name: 'Крайний срок отправки',
    key: 'shipment_date',
    type: 'date'
  }, {
    name: 'Быстрая доставка OZON Express',
    key: 'is_express',
    type: 'express'
  }]
}
