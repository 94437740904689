<template>
  <x-page
    card
    class="orders-tabs"
  >
    <div
      v-if="isLoading"
      class="orders-tabs__loader"
    >
      <loader />
    </div>

    <template v-else>
      <tabs
        type="marketplace"
        :tabs="ordersTabs"
        :active="currentTab.path"
        class="orders-tabs__tabs"
        :class="{'not-permit-by-tariff': !isPermitByTariff}"
        @click="onTabChange"
      />

      <filters
        :class="{'not-permit-by-tariff': !isPermitByTariff}"
        :status="orderStatus"
        :self-purchase="selfPurchase"
        :service-type="serviceType"
        :only-memo="onlyMemo"
        :shop="shop"
        :marketplace="currentTab"
        :created-period="createdPeriod"
        :payment-period="paymentPeriod"
        :send-period="sendPeriod"
        :dispute-period="disputePeriod"
        :filters-changed="filtersChanged"
        @search="term => filters.order_id = term"
        @change:shop="onShopChange"
        @change:shop-discount="checked => filters.shop_discount = checked"
        @change:marketplace-discount="checked => filters.marketplace_discount = checked"
        @change:created-period="onChangePeriod($event, 'createdPeriod', 'created', 'mmp_orders_filter_date_creation')"
        @change:payment-period="onChangePeriod($event, 'paymentPeriod', 'paid', 'mmp_orders_filter_date_pay')"
        @change:send-period="onChangePeriod($event, 'sendPeriod', 'sent', 'mmp_orders_filter_date_ship')"
        @change:dispute-period="onChangePeriod($event, 'disputePeriod', 'issue', 'mmp_orders_filter_date_argue')"
        @change:order-status="onStatusChange"
        @change:self-purchase="onSelfPurchaseChange"
        @change:service-type="onServiceTypeChange"
        @change:only-memo="onOnlyMemoChange"
        @reset="resetFilters"
      />

      <shop-limit-alert
        v-if="!isManager"
        class="mb-2"
      />

      <div
        v-if="isGridLoading"
        class="orders-tabs__loader"
      >
        <loader />
      </div>

      <template v-else>
        <template v-if="isPermitByTariff">
          <order-list
            :orders="orders"
            :marketplace="currentTab"
            :selected-ids="selectedIds"
            :download-pending="downloadPending"
            @download-click="onDownloadClick"
            @update-list="fetchData({}, $event)"
            @remove-all-selected="removeAllSelected"
          />

          <pagination
            v-if="orders.length"
            class="orders-tabs__pagination"
            :data="pagination"
            :current-page="currentPage"
            @change="onPaginationChange"
          />
        </template>
        <not-permit-by-tariff v-else />
      </template>

      <export-modal />
    </template>
  </x-page>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Tabs from '@/components/Interface/Tabs.vue'
import Filters from '@/components/Orders/Filters.vue'
import OrderList from '@/components/Orders/OrderList.vue'
import Loader from '@/components/Common/Loader.vue'
import { actionsSelfBuyout } from '@/constants/orders'
import Pagination from '@/components/Interface/Pagination.vue'
import PaginationMixin from '@/mixins/pagination'
import ExportModal from '@/components/Modals/ExportModal.vue'
import { defaultFilterOption, defaultSelfPurchase } from '@/constants/dashboards-filters'
import _debounce from 'lodash.debounce'
import PERMISSION_KEY from '@/constants/PERMISSION_KEY'
import NotPermitByTariff from '@/components/NotPermit/NotPermitByTariff'
import ShopLimitAlert from '@/components/NotPermit/ShopLimitAlert'

const { mapActions, mapState } = createNamespacedHelpers('orders')
const { mapActions: mapShopsActions } = createNamespacedHelpers('marketplaces')
const { mapActions: mapTrackingActions } = createNamespacedHelpers('tracking')
const { mapState: mapProfileState } = createNamespacedHelpers('profile')

const emptyOption = {
  start: null,
  end: null
}

let debouncedFetch

export default {
  mixins: [PaginationMixin],

  components: {
    ShopLimitAlert,
    NotPermitByTariff,
    Tabs,
    Filters,
    OrderList,
    Loader,
    Pagination,
    ExportModal
  },

  data () {
    return {
      selectedIds: [],
      isLoading: false,
      isGridLoading: false,
      downloadPending: false,
      orderStatus: defaultFilterOption,
      shop: defaultFilterOption,
      createdPeriod: emptyOption,
      paymentPeriod: emptyOption,
      sendPeriod: emptyOption,
      disputePeriod: emptyOption,
      selfPurchase: defaultSelfPurchase,
      serviceType: defaultFilterOption,
      onlyMemo: null,
      filters: {
        order_id: null,
        shop_id: 'ALL',
        xway_status: 'ALL',
        shop_discount: null,
        marketplace_discount: null,
        created_from: null,
        created_to: null,
        paid_from: null,
        paid_to: null,
        sent_from: null,
        sent_to: null,
        issue_from: null,
        issue_to: null,
        marketplace: null,
        self_buyout: 'ALL',
        service_type: 'ALL',
        has_memo: null
      },
      defaultFilters: {},
      searchTimeout: null
    }
  },

  provide () {
    return {
      fetchData: this.fetchData
    }
  },

  watch: {
    computedFilters: {
      handler () {
        if (this.currentPage === 1) {
          debouncedFetch()
          return
        }
        this.currentPage = 1
      },
      deep: true
    },
    'filters.order_id' () {
      clearTimeout(this.searchTimeout)

      this.searchTimeout = setTimeout(() => {
        this.$metrics.hit('mmp_orders_filter_search')
      }, 700)
    }
  },

  computed: {
    ...mapState(['orders', 'ordersTabs', 'ordersCount']),
    ...mapProfileState(['user']),

    activeTab () {
      return +this.$route.query?.marketplace || 'ALL'
    },

    currentTab () {
      if (!this.ordersTabs.length) return {}

      return this.ordersTabs.find(t => t.path === this.activeTab)
    },

    computedFilters () {
      return Object.keys(this.filters).reduce((filters, filter) => {
        filters[filter] = this.filters[filter] !== 'ALL' ? this.filters[filter] : null
        return filters
      }, {})
    },

    isPermitByTariff () {
      return this.$user.isPermitByTariff(PERMISSION_KEY.SELLER_ORDER_LIST)
    },
    filtersChanged () {
      return JSON.stringify(this.defaultFilters) !== JSON.stringify(this.filters)
    },
    isManager () {
      return this.user.service_model && this.user.service_model === 'xway-service'
    }
  },

  async created () {
    this.saveDefaultFilters()

    try {
      debouncedFetch = _debounce(this.fetchData, 300)

      this.isLoading = true
      await this.getMatketplacesList()
      await this.getShopList()
      this.fetchData()
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    } finally {
      this.isLoading = false
    }
  },

  methods: {
    ...mapActions([
      'getOrders',
      'getMatketplacesList',
      'downloadOrderList',
      'getXwayOrderStatuses'
    ]),
    ...mapShopsActions(['getShopList']),
    ...mapTrackingActions(['setEvent']),

    onChangePeriod (period, prop, filter, eventName) {
      this[prop] = period
      const { start, end } = period

      this.filters[`${filter}_from`] = start
      this.filters[`${filter}_to`] = end
      this.$metrics.hit(eventName)
    },

    onTabChange (marketplace) {
      this.filters.marketplace = marketplace
      this.resetFilters()
      this.saveDefaultFilters()
      this.selectedIds = []
    },

    onShopChange (option) {
      this.$metrics.hit('mmp_orders_filter_shop')
      this.filters.shop_id = option.id
      this.shop = option
    },

    onStatusChange (option) {
      this.$metrics.hit('mmp_orders_filter_status')
      this.filters.xway_status = option.id
      this.orderStatus = option
    },

    onSelfPurchaseChange (option) {
      this.$metrics.hit('mmp_orders_filter_selfpurchase')
      this.filters.self_buyout = option.id
      this.selfPurchase = option
    },

    onServiceTypeChange (option) {
      this.$metrics.hit('mmp_orders_filter_fulfillment')
      this.filters.service_type = option.id
      this.serviceType = option
    },

    onOnlyMemoChange (val) {
      if (val) {
        this.$metrics.hit('mmp_orders_only_with_comments')
        this.filters.has_memo = val
        this.onlyMemo = val
      } else {
        this.filters.has_memo = null
        this.onlyMemo = null
      }
    },

    resetFilters () {
      // Object.keys(this.filters).forEach(filter => {
      //   if (filter === 'marketplace') return
      //   this.filters[filter] = null
      // })
      this.filters = { ...this.defaultFilters }

      this.shop = this.orderStatus = defaultFilterOption
      this.selfPurchase = defaultSelfPurchase
      this.serviceType = defaultFilterOption
      this.onlyMemo = null
      this.createdPeriod = this.paymentPeriod = this.sendPeriod = this.disputePeriod = emptyOption
    },

    saveDefaultFilters () {
      this.defaultFilters = { ...this.filters }
    },

    removeAllSelected (ids) {
      this.selectedIds = this.selectedIds.filter(id => !ids.includes(id))
    },

    toPrevPage () {
      if (this.filters.self_buyout !== 'ALL') {
        const lastPage = Math.max(Math.ceil((this.ordersCount - this.selectedIds.length) / this.pagination.limit), 1)

        if (this.currentPage > lastPage) this.currentPage = lastPage

        this.$router.replace(
          {
            query: {
              ...this.$route.query,
              page: this.currentPage
            }
          }).catch(() => {})
      }
    },

    async fetchData (params = {}, action) {
      try {
        this.isGridLoading = true
        const marketplace = this.currentTab.id !== 'ALL' ? this.currentTab.id : null

        if (action === actionsSelfBuyout.SET_MARK || action === actionsSelfBuyout.REMOVE_MARK) {
          this.toPrevPage()
          this.selectedIds = []
        }

        params = {
          ...params,
          ...this.computedFilters,
          marketplace,
          page: this.currentPage,
          limit: this.pagination.limit
        }

        this.getXwayOrderStatuses({
          marketplace: this.currentTab.code
        })

        await this.getOrders(params)
        this.pagination.count = this.ordersCount
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err)
      } finally {
        this.isGridLoading = false
      }
    },

    async onDownloadClick () {
      const shopId = (this.currentTab.id === 'ALL') ? 0 : this.currentTab.id
      this.setEvent({
        name: 'mmp_orders_report_excel',
        product: 'MMP',
        screen: 'mmp_orders',
        shop_id: shopId,
        marketplace_code: this.currentTab.code,
        status: this.filters.status,
        creation_date: this.filters.created_from,
        payment_date: this.filters.paid_from,
        shipment_date: this.filters.sent_from,
        arguement_date: this.filters.issue_from
      })
      try {
        this.downloadPending = true

        const success = await this.downloadOrderList(this.computedFilters)
        if (success) {
          this.$modal.show('export-modal', { openInNewTab: true })
        }
      } finally {
        this.downloadPending = false
      }
    },

    onPaginationChange ({ page, limit, offset }) {
      this.currentPage = page

      this.pagination.limit = limit

      this.fetchData({
        page,
        limit,
        offset
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .orders-tabs
    &__loader
      padding 40px
      text-align center

    &__pagination
      margin-top 30px
      & >>> .pagination__select
        margin-right 5px
      & >>> .pagination__item
        flex-shrink 0
      & >>> .pagination__button
        display flex
        align-items center
        justify-content center
        min-width 34px
        width auto
        flex-shrink 0
        padding 0 5px

    & >>> .pagination__item_content_next .pagination__button-icon,
    >>> .pagination__item_content_prev .pagination__button-icon
      width 18px
      height 18px

</style>
