var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-card',{staticClass:"order-products",attrs:{"size":"xl"}},[_c('div',{staticClass:"headline-5 mb-6"},[_vm._v(" Подробности заказа ")]),_c('grid',{attrs:{"auto":"","no-hover-bg":"","default-sort-by":"id","cells":_vm.order.order_items,"columns":_vm.columns},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"order-products__meta"},[_c('x-img',{staticClass:"mr-2",attrs:{"src":row.image_url}}),_c('div',[_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(row.id)+" "),(row.link)?_c('x-link',{staticClass:"order-products__product-link ml-1",attrs:{"href":row.link}},[_c('x-icon',{attrs:{"name":"link","size":"xs"}})],1):_vm._e()],1),_c('div',[(row.sku_code)?_c('div',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:((row.sku_code).toString()),expression:"(row.sku_code).toString()",arg:"copy"}],staticClass:"caption gray-darker--text d-inline-flex align-center copy-text",on:{"click":function($event){return _vm.$emit('copied')}}},[_c('span',[_vm._v("Арт. МП: "+_vm._s(row.sku_code))]),_c('x-icon',{staticClass:"ml-1",attrs:{"name":"copy","size":"xxs"}})],1):_vm._e()]),_c('div',[(row.internal_id)?_c('div',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:((row.internal_id).toString()),expression:"(row.internal_id).toString()",arg:"copy"}],staticClass:"caption gray-darker--text d-inline-flex align-center copy-text",on:{"click":function($event){return _vm.$emit('copied')}}},[_c('span',[_vm._v("Арт. внут.: "+_vm._s(row.internal_id))]),_c('x-icon',{staticClass:"ml-1",attrs:{"name":"copy","size":"xxs"}})],1):_vm._e()]),_c('div',[(row.barcode)?_c('div',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:((row.barcode).toString()),expression:"(row.barcode).toString()",arg:"copy"}],staticClass:"caption gray-darker--text d-inline-flex align-center copy-text",on:{"click":function($event){return _vm.$emit('copied')}}},[_c('span',[_vm._v("Штрихкод: "+_vm._s(row.barcode))]),_c('x-icon',{staticClass:"ml-1",attrs:{"name":"copy","size":"xxs"}})],1):_vm._e()])])],1)]}},{key:"name",fn:function(ref){
var row = ref.row;
return [(row.name)?_c('tooltip',{attrs:{"no-arrow":""},scopedSlots:_vm._u([{key:"popover",fn:function(){return [_vm._v(" "+_vm._s(row.name)+" ")]},proxy:true}],null,true)},[_c('span',{staticClass:"order-products__name"},[_vm._v(" "+_vm._s(row.name)+" ")])]):_vm._e()]}},{key:"discount_amount_sum",fn:function(ref){
var discount_amount_sum = ref.discount_amount_sum;
return [_vm._v(" "+_vm._s(_vm.formatPrice(discount_amount_sum))+" руб. ")]}},{key:"price",fn:function(ref){
var price = ref.price;
return [(price)?[_vm._v(" "+_vm._s(_vm.formatPrice(price))+" руб. ")]:_vm._e()]}},{key:"product_count",fn:function(ref){
var product_count = ref.product_count;
return [_vm._v(" "+_vm._s(product_count)+" шт ")]}},{key:"append-item",fn:function(ref){
var cell = ref.cell;
return [(cell.memo)?_c('div',{staticClass:"order-memo"},[_c('Comment',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"mb-1 font-weight-semibold"},[_vm._v(" Комментарий к заказу ")])]},proxy:true},{key:"text",fn:function(){return [_c('div',[_vm._v(_vm._s(cell.memo))])]},proxy:true}],null,true)})],1):_vm._e()]}},{key:"total-values",fn:function(){return [_c('tr',{staticClass:"order-products__total font-weight-bold grid__cell grid__cell_viewtype_numbers"},[_c('td',{staticClass:"total-text",attrs:{"colspan":"3"}},[_vm._v(" Итого ")]),_c('td',[_c('div',{staticClass:"grid__cell-in"},[_c('div',[_vm._v(_vm._s(_vm.totalCount('product_count').split(',')[0])+" шт")])])]),_c('td',[_c('div',{staticClass:"grid__cell-in"},[_c('div',[_vm._v(_vm._s(_vm.totalCount('total_product_amount'))+" руб.")])])]),_c('td',[_c('div',{staticClass:"grid__cell-in"},[_c('div',[_vm._v(_vm._s(_vm.totalCount('discount_amount_sum'))+" руб.")])])]),_c('td',[_c('div',{staticClass:"grid__cell-in"},[_c('div',[_vm._v(_vm._s(_vm.totalCount('total_real_amount'))+" руб.")])])]),_c('td',[_c('div',{staticClass:"grid__cell-in"},[_c('div',[_vm._v(_vm._s(_vm.totalCount('logistics_amount'))+" руб.")])])])])]},proxy:true}])}),(_vm.order.pay_amount)?_c('div',{staticClass:"order-price d-flex align-center headline-6 mt-6"},[_vm._v(" Стоимость заказа: "),_c('div',{staticClass:"order-price__value ml-2 p-2 font-weight-semibold headline-4"},[_vm._v(" "+_vm._s((+_vm.order.rrc_sum).toLocaleString())+" руб. ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }