export default {
  new: 'Новый',
  checking: 'Проверка',
  confirmed: 'Подтверждён',
  equipping: 'Собирается',
  ready: 'Готов к отправке',
  delivering: 'Доставляется',
  partly_sent: 'Частично отправлен',
  in_issue: 'Спор',
  cancelled: 'Отменён',
  delivered: 'Доставлен',
  refund: 'Возврат'
}

export const statusColors = {
  new: 'success',
  checking: 'indigo-lighten-5',
  confirmed: 'light-blue-lighten-5',
  equipping: 'light-blue-lighten-5',
  ready: 'light-blue-lighten-5',
  delivering: 'light-blue-lighten-5',
  partly_sent: 'light-blue-lighten-5',
  in_issue: 'red-lighten-5',
  cancelled: 'orange-lighten-5',
  delivered: 'success-lightest-2',
  refund: 'orange-lighten-5'
}
