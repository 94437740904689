<template>
  <div class="marketing">
    <div
      v-if="columns.length"
      class="marketing__row"
    >
      <div
        v-for="key in columns"
        :key="key"
        class="column"
      >
        <div class="column__title gray-darkest-2--text font-weight-bold d-flex align-center mb-4">
          {{ columnTitle(key) }}
          <x-icon
            v-if="getHintText(key)"
            v-hint="{
              placement: 'top',
              content: getHintText(key),
              triggers: ['hover'],
              delay: 100
            }"
            class="ml-1"
            name="info"
            size="xs"
            color="gray"
          />
        </div>
        <div
          v-for="(row, index) in order.marketing[key]"
          :key="index"
          class="column__row mb-5"
        >
          <div class="column__block">
            <div class="column__label pr-5">
              {{ row.promotion_type }}
            </div>
            <div
              class="column__description pr-5"
              :title="row.description"
            >
              {{ row.description }}
            </div>
          </div>
          <div class="column__value">
            {{ row.discount_amount ? `${formatPrice(row.discount_amount)} руб` : '-' }}
          </div>
        </div>
      </div>
    </div>

    <x-placeholder
      v-else
      icon="no-data"
      desc="Купоны и промокоды не применялись"
    >
      <template #title>
        <div class="headline-4">
          Нет данных
        </div>
      </template>
    </x-placeholder>
  </div>
</template>

<script>
import formatPrice from '@/utils/price-formatter'
import { hints } from './shared'

export default {
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    columns () {
      return Object.keys(this.order.marketing)
    }
  },

  methods: {
    formatPrice,
    columnTitle (key) {
      switch (key) {
        case 'SELLER': return 'СЕЛЛЕР'
        case 'PLATFORM': return 'МАРКЕТПЛЕЙС'
        default: return 'ДРУГОЕ'
      }
    },
    getHintText (key) {
      return hints[key]?.hint
    }
  }
}
</script>

<style lang="stylus" scoped>
.marketing
  min-height: 140px
  &__row
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 50px

.column
  &__row
    display flex
    justify-content space-between

  &__block
    width 100%

  &__label
    display block
    color #9b9ba3
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    -webkit-box-orient: vertical;
    max-width 400px

  &__description
    font-size: $font-sizes.caption
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
    max-width 190px
    color #7B7B85

  &__value
    white-space nowrap

</style>
