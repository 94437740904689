export default {
  new: 'Новый',
  checking: 'На проверке',
  confirmed: 'Подтверждён',
  equipping: 'Сборка',
  ready: 'Готов к отгрузке',
  partly_sent: 'Частично отправлен',
  delivering: 'Доставляется',
  delivered: 'Доставлен',
  in_issue: 'Спор',
  partly_refund: 'Частичный возврат',
  refund: 'Возврат',
  cancelled: 'Отменён'
}

export const statusColors = {
  new: 'success',
  checking: 'yellow-lighten-4',
  confirmed: 'indigo-lighten-5',
  equipping: 'indigo-lighten-5',
  ready: 'indigo-lighten-5',
  partly_sent: 'indigo-lighten-5',
  delivering: 'indigo-lighten-5',
  delivered: 'success-lightest-2',
  in_issue: 'warning-lightest-3',
  partly_refund: 'gray-lighter',
  refund: 'gray-lighter',
  cancelled: 'error'
}
