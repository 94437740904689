var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('property-list',{staticClass:"col col-4",attrs:{"item":_vm.order,"properties":_vm.columns.firstColumn},scopedSlots:_vm._u([{key:"date",fn:function(ref){
var date = ref.date;
return [_vm._v(" "+_vm._s(date ? _vm.formatDate(date) : '-')+" ")]}},{key:"track_number",fn:function(ref){
var track_number = ref.track_number;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(_vm._s(track_number))]),(track_number)?_c('x-btn',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(track_number),expression:"track_number",arg:"copy"}],attrs:{"size":"xs","icon":"copy","ghost":""},on:{"click":function($event){return _vm.$emit('copied')}}}):_c('span',[_vm._v("-")])],1)]}},{key:"track_url",fn:function(ref){
var track_url = ref.track_url;
return [(track_url)?_c('a',{attrs:{"href":track_url,"target":"_blank","rel":"noopener noreferrer"}},[_vm._v(" "+_vm._s(track_url)+" ")]):[_vm._v(" - ")]]}}])}),_c('property-list',{staticClass:"col col-4",attrs:{"item":_vm.order,"properties":_vm.columns.secondColumn},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var status = ref.status;
return [_c('delivery-status',{attrs:{"status":status}})]}},{key:"delivery_price",fn:function(ref){
var delivery_price = ref.delivery_price;
return [_vm._v(" "+_vm._s(_vm.formatPrice(delivery_price))+" руб ")]}},{key:"warehouse",fn:function(ref){
var warehouse = ref.warehouse;
return [(warehouse)?[_vm._v(" "+_vm._s(warehouse.name)+" ")]:_vm._e()]}},{key:"date",fn:function(ref){
var date = ref.date;
return [_vm._v(" "+_vm._s(date ? _vm.formatDate(date) : '-')+" ")]}},{key:"express",fn:function(ref){
var express = ref.express;
return [_vm._v(" "+_vm._s(express ? 'Да' : 'Нет')+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }