<template>
  <div class="row">
    <property-list
      :properties="columns.firstColumn"
      :item="order"
      class="col col-4"
    >
      <template #total="{ total }">
        {{ isFinite(total) ? formatPrice(total) : '-' }}
      </template>
    </property-list>

    <property-list
      :properties="columns.secondColumn"
      :item="order"
      class="col col-4"
    >
      <template #status="{ status }">
        <pay-status :status="status" />
      </template>

      <template #date="{ date }">
        {{ date ? formatDate(date) : '-' }}
      </template>
    </property-list>
  </div>
</template>

<script>
import PropertyList from '@/components/Interface/PropertyList.vue'
import PayStatus from '@/components/Orders/PreviewTabs/PayStatus/PayStatus.vue'
import columns from './columns'
import formatDate from '@/utils/date-formatter'
import formatPrice from '@/utils/price-formatter'

export default {
  components: {
    PropertyList,
    PayStatus
  },
  props: {
    order: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      columns
    }
  },
  methods: {
    formatDate,
    formatPrice
  }
}
</script>
