<template>
  <div class="marketplace">
    <div class="marketplace__title">
      <svg-icon :name="marketplace.icon" />
    </div>

    <x-link
      class="d-inline-flex align-center mb-3"
      primary
      :href="marketplace.file_url"
      target="_blank"
      download
      metrics="mmp_orders_selfpurchase_template"
    >
      <x-icon
        class="mr-1"
        name="list"
        size="xs"
      />
      Скачать шаблон
    </x-link>

    <div
      v-if="file && !validFileFormat"
      class="error--text mb-2 caption font-weight-semibold"
    >
      Недопустимый формат файла
    </div>

    <file-upload
      :disabled="loading"
      accept=".xls, .xlsx"
      @reset="setFile(null)"
      @upload="setFile"
    >
      <x-btn
        class="marketplace__file-upload"
        :loading="loading"
      >
        Загрузить файл
      </x-btn>
    </file-upload>
  </div>
</template>

<script>
import FileUpload from '@/components/Interface/FileUpload.vue'
import { validFiles } from '@/constants/orders.js'

export default {
  components: {
    FileUpload
  },
  props: {
    marketplace: Object,
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      file: null
    }
  },
  computed: {
    validFileFormat () {
      return validFiles.includes(this.file.type)
    }
  },
  methods: {
    setFile (file) {
      this.file = file
      if (!this.validFileFormat) {
        setTimeout(() => {
          this.file = null
        }, 5000)
      } else {
        this.$emit('upload', this.file)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.marketplace
  display flex
  flex-direction column
  align-items center
  border-radius 8px
  padding 34px 24px 24px
  border: 1px dashed $colors.gray
  width 100%

  &__title
    margin-bottom 15px

  &__title svg
    width 90px
    height 25px

  &__file-upload
    margin-top auto

.file-name
  text-align center
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
</style>
