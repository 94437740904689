<template>
  <x-badge
    v-if="status"
    :color="statusColors[status]"
  >
    {{ orderStatuses[status] }}
  </x-badge>
</template>

<script>
import orderStatuses, { statusColors } from './shared'

export default {
  props: {
    status: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      orderStatuses,
      statusColors
    }
  }
}
</script>
