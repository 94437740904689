<template>
  <x-dialog
    cancel-text="Отмена"
    submit-text="Подтвердить"
    @submit="submit"
  >
    <template #title>
      <div class="title mb-5">
        {{ title }}
      </div>
    </template>
    <template #description>
      <div class="text mb-5">
        <x-link
          :href="SELF_REDEMPTION.link"
          primary
          metrics="mmp_orders_selfpurchase_guide"
        >
          {{ SELF_REDEMPTION.name }}
        </x-link>
      </div>
    </template>
  </x-dialog>
</template>

<script>
import { actionsSelfBuyout } from '@/constants/orders'
import { HELP_DOCS } from '@/constants/links'

export default {
  props: {
    selectedIds: Array,
    action: String
  },
  data: () => ({
    SELF_REDEMPTION: HELP_DOCS.SELF_REDEMPTION
  }),
  computed: {
    title () {
      return this.action === actionsSelfBuyout.REMOVE_MARK
        ? `Снять с ${this.selectedIds.length > 1 ? 'заказов' : 'заказа'} отметку «Самовыкуп»?`
        : `Отметить ${this.selectedIds.length > 1 ? 'заказы' : 'заказ'} как «Самовыкуп»?`
    }
  },
  methods: {
    submit () {
      this.$emit('onSubmit')
      this.$dialog.hide()
    }
  }
}
</script>

<style lang="stylus" scoped>
.title, .text
  text-align center
</style>
