export default {
  firstColumn: [{
    name: 'ФИО получателя',
    key: 'contact_person'
  }, {
    name: 'Телефон',
    key: 'mobile_no'
  }, {
    name: 'Регион / область',
    key: 'province'
  }, {
    name: 'Город',
    key: 'city'
  }],
  secondColumn: [{
    name: 'Полный адрес',
    key: 'address_full',
    styles: {
      display: 'block',
      maxWidth: '384px'
    }
  }, {
    name: 'Страна',
    key: 'country'
  }, {
    name: 'Почтовый индекс',
    key: 'zip'
  }]
}
